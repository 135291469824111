

































import { CertificateTypeModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Prop } from "vue-property-decorator";
import Api from "@/api";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CertificateTypeTable extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;

  private loading = false;
  private certificateTypes: Array<CertificateTypeModel> = [];
  private headers: Array<DataTableHeader<CertificateTypeModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CertificateTypeModel>("name") },
    {
      text: "Validity In Days",
      value: nameof<CertificateTypeModel>("validityInDays")
    },
    { text: "Created", value: nameof<CertificateTypeModel>("createdUtc") }
  ];

  private async created() {
    await this.getCertificateTypes();
  }

  private async getCertificateTypes() {
    try {
      this.loading = true;
      const response = await Api.CertificateTypeService.apiCertificatetypeDiscriminatorGet(getContractType(this.discriminator));
      this.certificateTypes = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch certificate types");
    } finally {
      this.loading = false;
    }
  }
}
