

























import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import CertificateTypeCreateDialog from "@/components/dialogs/CertificateTypeCreateDialog.vue";
import CertificateTypeUpdateDialog from "@/components/dialogs/CertificateTypeUpdateDialog.vue";
import CertificateTypeTable from "@/components/certificate-type/CertificateTypeTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CertificateTypeModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    CertificateTypeTable,
    CertificateTypeCreateDialog,
    CertificateTypeUpdateDialog
  }
})
export default class CertificateTypeOverview extends Vue {
  public tableRefreshKey = false;
  private certificateTypeId = "";
  private createDialog = false;
  private updateDialog = false;
  private loading = false;
  private search = "";
  private items: Array<CertificateTypeModel> = [];
  private headers: Array<DataTableHeader<CertificateTypeModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: "name" },
    { text: "Validity In Days", value: "validityInDays" },
    { text: "Created", value: "createdUtc" }
  ];

  public get discriminator() {
    return Discriminator.LAND_WORKERS;
  }

  private async created() {
    await this.getCertificateTypes();
  }

  private async getCertificateTypes() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  private async onUpdateCertificateType() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  private onCreateCertificateType() {
    this.createDialog = true;
  }

  private async onEditCertificateType(type: CertificateTypeModel) {
    if (!type.certificateTypeId) {
      return;
    }

    this.certificateTypeId = type.certificateTypeId;
    this.updateDialog = true;
  }

  private async onDeleteCertificateType(type: CertificateTypeModel) {
    if (!type.certificateTypeId) {
      return;
    }

    try {
      this.loading = true;
      await Api.CertificateTypeService.apiCertificatetypeCertificateTypeIdDelete(
        type.certificateTypeId
      );

      snackbarModule.setSnackbarMessage("Certificate type Deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete certificate type");
    } finally {
      this.loading = false;
    }

    await this.getCertificateTypes();
  }
}
