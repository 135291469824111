





































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { CertificateTypeUpsertModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CertificateTypeUpdateDialog extends Validation {
  @Prop(String) private certificateTypeId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: CertificateTypeUpsertModel = {
    name: "",
    validityInDays: 0,
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.CertificateTypeService.apiCertificatetypeCertificateTypeIdGet(
        this.certificateTypeId
      );
      this.model = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew member note");
      this.syncedDialog = false;
    }
  }

  private async onCertificateTypeUpdate() {
    try {
      await Api.CertificateTypeService.apiCertificatetypeCertificateTypeIdPut(
        this.certificateTypeId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Certificate type updated");
      this.$emit("certificate-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update certificate type");
    } finally {
      this.syncedDialog = false;
    }
  }
}
